import React, { useState, useCallback, useEffect } from 'react'

import { Table, Container, Row, Col, Button, Input } from 'reactstrap'
import PaginationComponent from 'components/PaginationComponent'
import Loading from 'components/Loading'
import api from 'configs/api'

import ModalRhAdmins from '../Components/ModalRhAdmins'

const RhCompanies = () => {
  const [name, setName] = useState('')
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [numberOfPages, setNumberOfPages] = useState(1)
  const [admins, setAdmins] = useState<any>()
  const [admin, setAdmin] = useState<any>()

  const clickAdm = useCallback(async (adm: any) => {
    setLoading(true)
    try {
      const { data } = await api.get(`/company/ms/v1/rh/user/${adm?.id}`)

      setAdmin({
        ...adm,
        companies: data.user?.companies.map((item: any) => ({
          value: item.company_id,
          label: item.legal_name,
        })),
      })

      setModal(true)
    } catch (err: any) {
      console.log('error', err?.response?.data)
    }
    setLoading(false)
  }, [])

  const getAdmins = async (nameCompany = name, numberPage = page) => {
    setLoading(true)

    try {
      const { data } = await api.get(
        `/company/ms/v1/rh/users?page=${numberPage}&name=${nameCompany}`
      )

      setNumberOfPages(Math.ceil(data.total / 10))
      setAdmins(data?.users)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    getAdmins()
  }, [page])

  return (
    <div>
      {loading && <Loading title="Aguarde..." />}
      {modal && (
        <ModalRhAdmins
          modal={modal}
          setModal={setModal}
          setLoading={setLoading}
          admin={admin}
          action={() => getAdmins()}
        />
      )}
      <Container className="mt-3" fluid>
        <Row className="align-items-center">
          <h3 className="mb-0 text-black">Administradores Cadastrados</h3>
        </Row>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="justify-content-start">
            <Button
              color="secondary"
              onClick={() => {
                setAdmin({})
                setModal(true)
              }}
            >
              <i className="fas fa-plus" />
            </Button>
          </Col>
          <Col sm="auto" className="d-flex justify-content-end">
            <Col xs="auto">
              <Input
                type="text"
                id="filtrar-name"
                value={name}
                placeholder="Filtrar pelo nome"
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col xs="auto">
              <Button
                color="secondary"
                onClick={() => {
                  getAdmins(name, 1)
                  setPage(1)
                }}
              >
                <i className="fas fa-search" />
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                onClick={() => {
                  getAdmins('', 1)
                  setName('')
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </Col>
          </Col>
        </Row>
        <Row className="mt-3">
          <Table
            className="align-items-center table-flush"
            responsive
            hover
            fluid="true"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">id</th>
                <th scope="col">nome</th>
                <th scope="col">email</th>
                <th scope="col">status</th>
              </tr>
            </thead>

            <tbody>
              {admins?.map((adm: any) => (
                <tr
                  key={adm.id}
                  onClick={() => clickAdm(adm)}
                  style={{ cursor: 'pointer' }}
                >
                  <th
                    style={{
                      width: '25%',
                    }}
                  >
                    {adm.id}
                  </th>
                  <td>{adm.name}</td>
                  <td>{adm.email}</td>
                  <td>{adm.status === 'active' ? 'Ativo' : 'Inativo'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Col className="align-items-end">
            <PaginationComponent
              numberOfPages={numberOfPages}
              page={page}
              setPage={setPage}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RhCompanies
