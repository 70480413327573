/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import { alertWarning, alertSuccess } from 'utils/toast'
import Loading from 'components/Loading'

import { getStorage } from 'utils/storage'
import { useDropzone } from 'react-dropzone'
import ReactDropZoneInput from 'components/ReactDropzoneInput'

import PaginationComponent from 'components/PaginationComponent'
import { format } from 'date-fns'

import * as EmailValidator from 'email-validator'

// eslint-disable-next-line camelcase
import { telefone_validation } from 'utils/cellphoneValidator'
import { validarCPF } from 'utils/cpfValidator'

import { read, utils } from 'xlsx'

import api from 'configs/api'
import { colaboradores } from './Components/rules'

const ImportUser = () => {
  const location = useLocation()
  const { push } = useHistory()
  const company: any = location.state || {}

  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)

  const [fileToUpload, setFileToUpload] = useState<any | null>(null)
  const [usersinvalid, setUsersInvalid] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [historic, setHistoric] = useState<any>('')
  const [typeUser, setTypeUser] = useState<any>('holder')

  const [invalids, setInvalids] = useState<any>(false)

  const [openState, setOpenState] = useState(
    Array(colaboradores.length).fill(false)
  )

  const dataUser = getStorage('@ManagerStarbem:user')

  // react-dropzone config
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0]

      if (company.type === 'remove') {
        return setFileToUpload(file)
      }

      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (e: any) => {
          const data = e.target.result
          const workbook = read(data, { type: 'binary' })

          const sheetName = workbook.SheetNames[0]
          const worksheet: any = workbook.Sheets[sheetName]
          const range = utils.decode_range(worksheet['!ref'])
          range.s.r = 2
          worksheet['!ref'] = utils.encode_range(range)

          const novadata = utils
            .sheet_to_csv(worksheet, { FS: ',' })
            .replace(/^(?:,|\n)+|(?:,|\n)+$/g, '')

          if (novadata) {
            const lines = novadata.toString().split('\n')
            const users: string[] = []
            lines.map((line: string, index: number) => {
              if (index > 0) {
                const result = line.split(',')

                if (company.type === 'titular') {
                  setFileToUpload(file)
                }

                if (company.type === 'dependente') {
                  const emailValidator = EmailValidator.validate(
                    result[2].trim()
                  )

                  if (result[1]?.trim().length < 5) {
                    users.push(`${result[3]} (Nome inválido)`)
                    setInvalids(true)
                  } else if (!validarCPF(result[3])) {
                    users.push(`${result[1]} (CPF inválido)`)
                    setInvalids(true)
                  } else if (!emailValidator) {
                    users.push(`${result[1]} (Email inválido)`)
                    setInvalids(true)
                  } else if (!telefone_validation(result[4])) {
                    users.push(`${result[1]} (Telefone inválido)`)
                    setInvalids(true)
                  }
                }
              }
            })

            setUsersInvalid(users)
          }

          resolve(novadata)
        }
        reader.readAsBinaryString(file)
        setFileToUpload(file)
      })
    },
    [company.type]
  )

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      accept:
        company.type === 'remove'
          ? '.csv, application/vnd.ms-excel, text/csv'
          : '.xlsx,  text/xlsx',
      multiple: false,
    })

  const getCompany = async () => {
    setLoading(true)
    try {
      const response = await api.get(
        `/accounts/ms/v1/user/import/history?company_id=${company.id}&type=internal&page=${page}&take=10&typeUser=${typeUser}`
      )

      setHistoric(response.data)
      setNumberOfPages(Math.ceil(parseInt(response.data.total, 10) / 10))
    } catch (err: any) {
      console.log('error', err)
    }

    setLoading(false)
  }

  const handleSubmit = async () => {
    try {
      if (fileToUpload) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileToUpload)
        formData.append('company_id', company?.id)
        formData.append('plan_id', company?.plan)
        formData.append('user_id', dataUser?.id)
        formData.append('user_name', dataUser?.name)
        formData.append('type', 'internal')
        formData.append('company_email', company?.email)

        let url = ''

        if (company.type === 'titular') {
          url = '/accounts/ms/v1/user/import'
        } else if (company.type === 'dependente') {
          url = 'manager/b2b/import-dependents?internal=true'
        } else if (company.type === 'remove') {
          url = 'manager/b2b/remove'
        }

        const { data } = await api.post(url, formData)
        if (data?.usersErrors.length > 0) {
          const invalidCpfs = data.usersErrors.map((item: any) => item)
          setUsersInvalid(invalidCpfs)
        } else {
          acceptedFiles.shift()
          alertSuccess(data?.message || 'Sucesso!')
        }

        setLoading(false)
      } else {
        alertWarning('É necessário informar um arquivo.')
      }
    } catch (err: any) {
      alertWarning(err?.response?.data?.message)
    } finally {
      getCompany()
      setLoading(false)
    }
  }

  const toggleCollapsible = (index: any) => {
    const newOpenState = [...openState]
    newOpenState[index] = !newOpenState[index]
    setOpenState(newOpenState)
  }

  useEffect(() => {
    getCompany()
    if (company.type === 'dependente') {
      setTypeUser('dependet')
    } else {
      setTypeUser('holder')
    }
  }, [page])

  const ContentCpfsInvalid = () => (
    <div className="d-flex flex-column justify-content-space-between">
      <div className="text-center">
        <h1 className="text-uppercase">Dados inválidos</h1>
        <p className="font-weight-bold text-muted">
          {invalids && 'Os seguintes usuários possuem dados inválidos:'}
        </p>
      </div>
      <div
        className="d-flex flex-column h-100 justify-content-center"
        style={{ flex: 1, height: '68%', overflowY: 'auto' }}
      >
        <ul>
          {company.type === 'titular' &&
            usersinvalid.map((item) => (
              <li key={item} className="font-italic">
                {item.user}
                <span> - </span>
                {item.message}
              </li>
            ))}

          {company.type === 'dependente' &&
            usersinvalid.map((item) => (
              <li key={item} className="font-italic">
                {item}
              </li>
            ))}
        </ul>
      </div>
      <div
        className="d-flex align-items-end justify-content-end"
        style={{ height: '12%' }}
      >
        <div>
          <Button
            disabled={!fileToUpload}
            onClick={() => {
              setFileToUpload(null)
              setUsersInvalid([])
              setInvalids(false)
              acceptedFiles.shift()
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      {loading && <Loading title="Aguarde..." />}

      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="align-items-start ">
            <Button
              color="secondary"
              onClick={() => {
                push(`/admin/empresa/${company?.id}`)
              }}
            >
              <i className="fas fa-arrow-left" />
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
            <Col sm="auto">
              <h2 className="mb-0 text-black">
                {company.type === 'titular' && 'Importação de Titulares'}
                {company.type === 'dependente' && 'Importação de Dependente'}
                {company.type === 'remove' && 'Remoção Titulares'}
              </h2>
            </Col>
          </CardHeader>

          <CardBody className="shadow">
            <Row className="m-1">
              <Col lg="6" className="lg: mb-5">
                <h3 className="text-black">Como preencher Excel (.xlsx)</h3>
                <p>
                  Algumas regras de preenchimento de cada coluna da planilha
                  modelo
                </p>
                {colaboradores.map((colaborador) => (
                  <div key={colaborador.id}>
                    <div
                      className="collapsible d-flex justify-content-between text-primary"
                      onClick={() => toggleCollapsible(colaborador.id)}
                      role="button"
                      tabIndex={0}
                      aria-expanded={openState[colaborador.id]}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ')
                          toggleCollapsible(colaborador.id)
                      }}
                    >
                      {colaborador.title}
                      <i
                        className={`fas ${
                          openState[colaborador.id]
                            ? 'fa-chevron-up'
                            : 'fa-chevron-down'
                        }`}
                        style={{ fontSize: '15px', marginLeft: '10px' }}
                      />
                    </div>
                    {openState[colaborador.id] && (
                      <div className="content">
                        <p>{colaborador.text}</p>
                      </div>
                    )}
                  </div>
                ))}
              </Col>

              <Col lg="6">
                <h3 className="text-black">Adicionar planilha</h3>
                <p>
                  Selecione o arquivo com as informações dos seus colaboradores
                </p>
                {usersinvalid.length > 0 && <ContentCpfsInvalid />}
                {usersinvalid.length === 0 && (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center mt-4 mb-4 "
                      style={{ flex: 1, padding: 0, margin: 0 }}
                    >
                      <ReactDropZoneInput getRootProps={getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p
                            className="font-weight-bold text-muted "
                            style={{ fontSize: 14 }}
                          >
                            Solte o arquivo aqui...
                          </p>
                        ) : acceptedFiles.length > 0 ? (
                          <p
                            className="font-weight-bold text-muted"
                            style={{ fontSize: 14 }}
                          >
                            {acceptedFiles[0].name}
                          </p>
                        ) : (
                          <>
                            <i
                              className="fas fa-file"
                              style={{ fontSize: '40px', marginBottom: '10px' }}
                            />
                            <p
                              className="font-weight-bold"
                              style={{ fontSize: 14 }}
                            >
                              Arraste e solte o arquivo aqui, ou clique para
                            </p>
                            <Button
                              style={{ marginBottom: '10px' }}
                              color="primary"
                            >
                              Selecionar arquivo
                            </Button>
                            <p
                              className="font-weight-bold text-muted"
                              style={{ fontSize: 14 }}
                            >
                              A extensão do arquivo deve ser .xlsx e pode ter
                              até 1000 colaboradores. O nome do arquivo não pode
                              conter caracteres especiais e não pode ultrapassar
                              50 caracteres.
                            </p>
                          </>
                        )}
                      </ReactDropZoneInput>
                    </div>

                    <div>
                      <Button
                        disabled={
                          !fileToUpload ||
                          (!fileToUpload && company.type === 'dependente')
                        }
                        onClick={() => handleSubmit()}
                      >
                        {company.type === 'remove' ? 'Remover' : 'Importar'}
                      </Button>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <p className="font-weight-bold">
                        Para ver um exemplo do formato obrigatório:
                      </p>
                      {company.type === 'dependente' && (
                        <Button
                          color="primary"
                          outline
                          href="https://starbem-production.nyc3.digitaloceanspaces.com/generic/import-b2b/Importador%20de%20colaboradores%20dependentes.xlsx"
                          className="lg-3"
                        >
                          Baixar modelo
                        </Button>
                      )}
                      {company.type === 'titular' && (
                        <Button
                          color="primary"
                          outline
                          href="https://docs.google.com/spreadsheets/d/18YJtZ_CJyQ2JdJWk71JSiic_aSij_vZZNnfFQhAkzMo/edit?gid=2040882291#gid=2040882291"
                          className="lg-3"
                          target="_blank"
                        >
                          Abrir modelo
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
          <Card className="shadow">
            <Col className="mt-4 mb-4">
              <h3 className="text-black">
                {company.type === 'titular' &&
                  'Histórico de importações titulares'}
                {company.type === 'dependente' &&
                  'Histórico de importações dependentes'}
                {company.type === 'remove' &&
                  'Histórico de remoção de titulares'}
              </h3>
            </Col>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Nome da planilha</th>
                  <th scope="col">Quantidade de importados</th>
                  <th scope="col">Quantidade de falhas</th>
                  <th scope="col">Data e hora</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>

              <tbody>
                {historic?.history?.map((user: any) => (
                  // eslint-disable-next-line no-underscore-dangle
                  <tr key={user._id}>
                    <th>{user.userName}</th>
                    <td>{user.name}</td>
                    <td>{user.quantityImport}</td>
                    <td>{user.quantityErrors}</td>
                    <td>
                      {user?.date
                        ? `${format(
                            new Date(user?.date),
                            'dd/MM/yyyy'
                          )} - ${format(new Date(user?.date), 'HH:mm')}`
                        : ''}
                    </td>
                    <td
                      style={
                        user.status === 'imported'
                          ? {
                              color: '#009847',
                            }
                          : { color: '#DD2E3A' }
                      }
                    >
                      {user.status === 'imported' ? 'Concluído' : 'Falhou'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <PaginationComponent
              numberOfPages={numberOfPages}
              page={page}
              setPage={setPage}
            />
          </Card>
        </Card>
      </Container>
    </div>
  )
}

export default ImportUser
