export const colaboradores = [
  {
    id: 1,
    title: 'Nome (Obrigatório)',
    text: 'Nesta coluna, coloca-se o nome e sobrenome do colaborador ',
  },
  {
    id: 2,
    title: 'E-mail (Obrigatório)',
    text: 'Nesta coluna, coloca-se o e-mail do colaborador',
  },
  {
    id: 3,
    title: 'Número de Telefone (Obrigatório)',
    text: 'Nesta coluna, coloca-se o DDD e o número (ex: (11) 12345-6789)',
  },
  {
    id: 4,
    title: 'Departamento (Obrigatório)',
    text: 'Nesta coluna, coloca-se o departamento do colaborador',
  },
  {
    id: 5,
    title: 'Data de nascimento (Opcional)',
    text: 'Nesta coluna, coloca-se o dia, mês e ano (ex: 00/00/0000)',
  },
  {
    id: 6,
    title: 'Gênero (Opcional)',
    text: 'Nesta coluna, coloca-se o gênero do colaborador como feminino, masculino ou outros',
  },
  {
    id: 7,
    title: 'CPF (Opcional)',
    text: 'Nesta coluna, coloca-se o CPF do colaborador',
  },
]
