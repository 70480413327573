import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Card, CardHeader, Table, Container, Row, Col } from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'
import api from 'configs/api'

const Terms = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [terms, setTerms] = useState([])

  const getTerms = async () => {
    setLoading(true)
    try {
      const response = await api.get('/backoffice/ms/v1/terms')
      setTerms(response?.data)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    getTerms()
  }, [])

  return (
    <div>
      <HeaderNeutro />
      {/* <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="justify-content-start">
            <Link to="/admin/termo/novo">
              <Button color="secondary">
                <i className="fas fa-plus" />
              </Button>
            </Link>
          </Col>
        </Row>
      </Container> */}
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              {loading ? (
                <Loading title="Aguarde..." />
              ) : (
                <>
                  <CardHeader className="d-flex justify-content-between">
                    <Col sm="9" className="mb-2">
                      <h3 className="mb-0 text-black">Termos Cadastrados</h3>
                    </Col>
                  </CardHeader>
                  <Row className="mt-0">
                    <div className="col">
                      <Card className="shadow">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                          hover
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">id</th>
                              <th scope="col">aplicação</th>
                              <th scope="col">linguagem</th>
                              <th scope="col">data do cadastro</th>
                              <th scope="col">data da atualização</th>
                            </tr>
                          </thead>
                          <tbody>
                            {terms?.map((term: any) => (
                              <tr
                                key={term.id}
                                onClick={() => {
                                  history.push(`/admin/termo/${term.id}`, {
                                    term,
                                  })
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <th scope="row">{term.id}</th>
                                <td>{term.application}</td>
                                <td>{term.lang}</td>
                                <td>
                                  {moment(term.created_at)
                                    .utc()
                                    .format('DD/MM/YYYY')}
                                </td>
                                <td>
                                  {moment(term.updated_at)
                                    .utc()
                                    .format('DD/MM/YYYY')}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Card>
                    </div>
                  </Row>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}
export default Terms
