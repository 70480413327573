import React, { useState, useMemo, useEffect } from 'react'
import * as yup from 'yup'
import Select from 'react-select'
import { useFormik, FormikProvider } from 'formik'
import { telephoneMask, telephoneUnmask } from 'js-essentials-functions'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Form,
  FormGroup,
  Row,
  Col,
} from 'reactstrap'
import { alertDanger, alertSuccess, alertWarning } from 'utils/toast'
import api from 'configs/api'
import InputPassword from 'components/InputPassword'

const validationSchema = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  username: yup
    .string()
    .required('Digite o nome de usuário.')
    .matches(
      /^[a-z0-9._-]+$/,
      'O nome de usuário deve conter apenas letras minúsculas, números, pontos, hífens ou sublinhados, sem espaços.'
    )
    .strict(true),
  email: yup
    .string()
    .required('Informe o email!')
    .test('email', 'Digite um email válido', (value) =>
      value ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value) : true
    ),
  password: yup.string(),
  cellphone: yup
    .string()
    .required('Informe o telefone!')
    .test('cellphone', 'Digite um telefone válido', (value) =>
      value
        ? /^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/i.test(telephoneUnmask(value))
        : true
    ),
})

const ModalRhAdmins = ({ modal, setModal, setLoading, admin, action }: any) => {
  const [companies, setCompanies] = useState<any[]>([])
  const [statusActive] = useState(admin?.status === 'active')
  const [modalRemove, setModalRemove] = useState(false)

  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      username: '',
      password: '',
      cellphone: '',
      companies: [],
    }),
    []
  )
  const formatItems = (items: any) => {
    if (items && items.length > 0) {
      return items.map((item: any) => ({
        value: item.id,
        label: item.legal_name,
      }))
    }
    return []
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const companiesA = values?.companies.map((comp: any) => ({
          company_id: comp.value,
        }))
        if (admin?.id) {
          const res = await api.put(`/company/ms/v1/rh/user/${admin.id}`, {
            username: values.username,
            name: values.name,
            companies: companiesA,
            email: values.email,
            cellphone: values.cellphone,
            password: values.password,
          })
          action()
          alertSuccess(res?.data?.message || 'Sucesso!')
        } else {
          const res = await api.post(`/company/ms/v1/rh/user`, {
            username: values.username,
            name: values.name,
            email: values.email,
            ddi: '55',
            cellphone: values.cellphone,
            companies: companiesA,
          })
          action()
          alertSuccess(res?.data?.message || 'Sucesso!')
        }
      } catch (err: any) {
        alertDanger(err?.response?.data?.message)
      }
      setModal(false)
      setLoading(false)
    },
  })

  const toggle = () => {
    formik.resetForm()
    setModal(!modal)
  }

  useEffect(() => {
    formik.setValues({
      name: admin?.name || '',
      username: admin?.username || '',
      email: admin?.email || '',
      cellphone: admin?.cellphone || '',
      companies: admin?.companies || [],
      password: '',
    })
  }, [admin])

  const handleRemove = async () => {
    setLoading(true)
    try {
      const response = await api.delete(`/company/ms/v1/rh/user/${admin?.id}`)
      alertSuccess(response?.data?.message || 'Usuário removido com sucesso')
      toggle()
      action()
    } catch (err: any) {
      alertWarning(err?.response?.data?.message || 'Erro ao remover usuário')
    }
    setModalRemove(false)
    setLoading(false)
  }

  const contentModalRemove = () => (
    <Modal
      isOpen={modalRemove}
      toggle={() => {
        setModalRemove(modalRemove)
      }}
    >
      <ModalHeader>
        <h3>Você deseja realmente remover esse administrador?</h3>
      </ModalHeader>
      <ModalBody className="p-4 d-flex justify-content-between">
        <Button
          color="primary"
          onClick={() => {
            handleRemove()
          }}
        >
          Sim
        </Button>
        <Button
          style={{ minWidth: 200 }}
          color="secondary"
          onClick={() => {
            setModalRemove(false)
          }}
        >
          Não
        </Button>
      </ModalBody>
    </Modal>
  )

  const getCompanies = async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`/company/ms/v1/rh/companies`)

      setCompanies(formatItems(data))
      setModal(true)
    } catch (err: any) {
      console.log('error', err?.response?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <Modal isOpen={modal} toggle={toggle} style={{ minWidth: 600 }}>
      <ModalHeader toggle={toggle}>
        <h3>
          {admin.id ? `Editar administrador` : `Cadastrar novo administrador`}
        </h3>
      </ModalHeader>
      <ModalBody className="p-4">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label for="name">Nome</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    disabled={!statusActive && admin.id}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.errors.name}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <div className="input-feedback">{formik.errors.name}</div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="email">Email</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="off"
                    disabled={!statusActive && admin.id}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <div className="input-feedback">{formik.errors.email}</div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    autoComplete="off"
                    disabled={!statusActive && admin.username}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    error={formik.errors.username}
                  />
                  {formik.errors.username && formik.touched.username && (
                    <div className="input-feedback">
                      {formik.errors.username}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="username">Celular</Label>
                  <Input
                    type="text"
                    name="cellphone"
                    id="cellphone"
                    autoComplete="off"
                    maxLength={15}
                    disabled={!statusActive && admin.cellphone}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={telephoneMask(formik.values.cellphone)}
                    error={formik.errors.cellphone}
                  />
                  {formik.errors.cellphone && formik.touched.cellphone && (
                    <div className="input-feedback">
                      {formik.errors.cellphone}
                    </div>
                  )}
                </FormGroup>
              </Col>
              {admin.id && (
                <Col sm="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="new">
                      Nova Senha
                    </label>
                    <InputPassword
                      id="new"
                      placeholder=""
                      autocomplete="off"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={!!formik.errors.password}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <div className="input-feedback">
                        {formik.errors.password}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              )}
              {admin.id && (
                <Col xs="auto">
                  <Button
                    color="primary"
                    onClick={() => {
                      setModalRemove(true)
                    }}
                  >
                    <i className="far fa-trash-alt" />
                  </Button>
                </Col>
              )}

              <Col sm="12">
                <FormGroup>
                  <Label htmlFor="email">Empresas</Label>
                  <Select
                    placeholder="Selecione as empresas..."
                    isMulti
                    id="companies"
                    defaultValue={admin?.companies}
                    options={companies}
                    value={formik.values.companies}
                    onChange={(e: any) => {
                      formik.setValues({
                        ...formik.values,
                        companies: e,
                      })
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.companies && formik.touched.companies && (
                    <div className="input-feedback">
                      {formik.errors.companies}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button
                  type="submit"
                  color="secondary"
                  disabled={
                    !formik.isValid ||
                    (statusActive && formik.values.companies.length === 0)
                  }
                  onClick={!formik.isValid ? toggle : () => {}}
                >
                  {admin?.id ? `Salvar` : `Cadastrar`}
                </Button>
                <Button outline color="primary" onClick={toggle}>
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </ModalBody>
      {contentModalRemove()}
    </Modal>
  )
}
export default ModalRhAdmins
